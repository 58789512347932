import { createGlobalStyle } from 'styled-components'
import { DESKTOP, TABLET } from '@nordic-web/ui-tokens'

const customMedia = {
  tablet: `@media screen and (min-width: ${TABLET}px)`,
  desktop: `@media screen and (min-width: ${DESKTOP}px)`,
}

const customColors = {
  baseBackground: '#0D0D0F',
  baseBackgroundLight: '#171719',
  background: '#E9E9E9',
}

const Title1 = `
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
  ${customMedia.tablet} {
    font-size: 40px;
  }
  ${customMedia.desktop} {
    font-size: 56px;
  }
`

const Title3 = `
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
  ${customMedia.tablet} {
    font-size: 21px;
  }
  ${customMedia.desktop} {
    font-size: 23px;
  }
`

const Body1 = `
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  ${customMedia.tablet} {
    font-size: 21px;
  }
  ${customMedia.desktop} {
    font-size: 23px;
  }
`

const Body3 = `
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  ${customMedia.tablet} {
    font-size: 16px;
  }
  ${customMedia.desktop} {
    font-size: 18px;
  }
`

const Body3Strong = `
  ${Body3}
  font-weight: 500;
`

const Body4 = `
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  ${customMedia.tablet} {
    font-size: 14px;
  }
  ${customMedia.desktop} {
    font-size: 16px;
  }
`

const Body4Strong = `
  ${Body4}
  font-weight: 500;
`

const CTA = `
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
`

const Info = `
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
`

export const HumanyGlobalStyle = createGlobalStyle`
  .humany-widget {
    /* CUSTOM FONTS */
    *,
    .humany-widget-header h1,
    .humany-component-search form input,
    .humany-text,
    .humany-link,
    .humany-button,
    .humany-input {
      font-family: 'FyranSans';
    }

    /* HEADER */
    .humany-widget-header {
      padding: 60px 0px;

      ${customMedia.tablet} {
        padding: 100px 0px;
      }

      h1 {
        ${Title1}
      }
    }

    /* CONTAINER */
    .humany-component-view-area {
      background-color: ${customColors.background};
      padding: 24px 16px;

      ${customMedia.tablet} {
        padding: 32px 10%;
      }
    }

    /* SEARCH */
    .humany-component-search {
      padding: 0;

      form {
        border-color: ${({ theme }) => theme.color.border['100']};
        padding: 18px 24px;

        button {
          font-size: 16px;
        }

        svg {
          width: 24px;
          height: 24px;
        }

        input {
          ${Body1}
          padding: 0;
          color: ${({ theme }) => theme.color.surface.black60};

          &::placeholder {
            color: ${({ theme }) => theme.color.surface.black60};
          }
        }

        &[data-has-focus="true"] {
          svg * {
            stroke: ${({ theme }) => theme.color.surface.black100};
          }
        }
      }
    }

    /* GUIDE LIST */
    .humany-component-guide-list {
      padding: 16px;

      ${customMedia.tablet} {
        padding: 40px;
      }

      .humany-paragraph h1 {
        ${Body3Strong}
        color: ${customColors.baseBackground};
      }

      .humany-list li {
        margin: 0;
        padding: 4px 8px 4px 0px;

        a {
          ${Body3}
          color: ${customColors.baseBackgroundLight};

          svg line {
            stroke: ${customColors.baseBackgroundLight};
          }
        }
      }

      > .humany-text-link {
        ${Body4}
        color: ${({ theme }) => theme.color.text.secondaryInverted};

        svg {
          display: none;
        }

        span {
          text-decoration: none;
        }
      }
    }

    /* Guide */
    .humany-component-guide {
      .humany-guide-content {
        border: none;
      }

      .humany-guide-body {
        padding: 24px 24px 0px 24px;

        ${customMedia.tablet} {
          padding: 48px 40px 0px 40px;
        }

        h1 {
          ${Title3}
          margin-bottom: 16px;
        }

        .humany-html {
          ${Body3}
        }

        &[accordion="true"] {
          background-color: white;
          padding: 0;
          border: none;
        }
      }
      .humany-feedback, .humany-dialog {
        padding: 24px 24px 24px 24px;

        ${customMedia.tablet} {
          padding: 32px 40px 48px 40px;
        }

        .humany-text {
          ${Body3Strong}
          color: ${customColors.baseBackground};
        }

        .humany-feedback-button, .humany-dialog-link {
          background: linear-gradient(180deg, rgba(173, 173, 173, 0.40) 0%, rgba(165, 165, 165, 0.40) 79.69%, rgba(195, 195, 195, 0.40) 100%);
          padding: 8px 32px;
          width: auto;
          border: none;
          margin-right: 16px;

          i {
            display: none;
          }

          span {
            ${CTA}
            color: ${customColors.baseBackground};
          }
        }
      }
    }

    /* CONTACT LINK */
    .humany-component-contact-link {
      padding: 56px;

      svg {
        width: 40px;
        height: 40px;

        path {
          stroke: ${customColors.baseBackgroundLight};
        }
      }

      span:nth-child(2) {
        ${Title3}
        color: ${customColors.baseBackground};
      }

      span:nth-child(3) {
        ${Body4}
        color: ${({ theme }) => theme.color.text.secondaryInverted};
      }
    }

    /* Category tags */
    .humany-component-guide-category-list {
      .humany-list {
        justify-content: flex-start;

        li {
          box-shadow: none;
          border-radius: 8px;
          margin: 0px 8px 8px 0px;
        }
      }

      &[data-item-type="tiles"] {
        .humany-list {
          li {
            a {
              padding: 40px;
              i {
                margin: 0px;
              }
              span {
                margin-top: 12px;
              }
            }
          }
        }
      }

      &[data-item-type="badges"] {
        .humany-list {
          li {
            &:hover {
              transform: scale(1);
            }
            a {
              padding: 8px;

              i {
                margin: 0px;
                margin-right: 8px;
              }
            }
          }

          li[data-selected="true"] a {
            background-color: rgba(0, 0, 0, 0.16);
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.19) inset;

            span {
              color: ${({ theme }) => theme.color.text.primaryInverted};
            }
          }
        }
      }
    }

    /* Related Guide List */
    .humany-component-related-guide-list {
      .humany-paragraph h1 {
        ${Body3Strong}
        color: ${customColors.baseBackground};
      }

      .humany-list {
          display: flex;
          flex-direction: column;

          li {
            margin-bottom: 0px;

            a {
              ${Body3}
              padding: 4px 0px;
              color: ${customColors.baseBackgroundLight};
              display: flex;

              svg {
                margin: 0px;

                ${customMedia.tablet} {
                  width: 24px;
                  height: 24px;
                }

                line {
                  stroke: ${({ theme }) => theme.color.surface.black100};
                }
              }

              span {
                margin-left: 8px;
              }
            }
          }
        }
    }

    /* Breadcrumbs */
    .humany-component-breadcrumbs {
      li {
        a {
          ${Body4Strong}
          color: ${({ theme }) => theme.color.text.secondaryInverted};
        }

        span {
          ${Body4Strong}
          color: ${({ theme }) => theme.color.text.primaryInverted};
        }
      }
    }

    /* Browse categories */
    .humany-component-guide-category-browser {
      .humany-guide-category-browser-list {
        box-shadow: none;
        padding: 16px;

        ${customMedia.tablet} {
          padding: 40px 48px;
        }

        .humany-paragraph h1 {
          ${Title3}
          color: ${customColors.baseBackground};
        }

        .humany-list {
          display: flex;
          flex-direction: column;

          li {
            border-bottom: 1px solid ${({ theme }) => theme.color.surface.black20};
            margin-bottom: 0px;

            &:last-child {
              border-bottom: none;
            }

            a {
              ${Body3}
              padding: 16px 8px;
              color: ${customColors.baseBackgroundLight};
              display: flex;

              svg {
                margin: 0px;

                ${customMedia.tablet} {
                  width: 24px;
                  height: 24px;
                }

                line {
                  stroke: ${customColors.baseBackgroundLight};
                }
              }

              span {
                margin-left: 16px;
              }
            }
          }
        }

        > .humany-text-link {
          ${Body4}
          color: ${({ theme }) => theme.color.text.secondaryInverted};
          margin-top: 16px;

          svg {
            display: none;
          }

          span {
            text-decoration: none;
          }
        }
      }
    }

    /* Footer */
    .humany-component-footer-area {
      .humany-component-back-link button {
        ${Body3Strong}
        color: ${customColors.baseBackgroundLight};
        text-decoration: none;

        svg {
          margin-right: 8px;

          line {
            stroke: ${customColors.baseBackgroundLight};
          }
        }
      }

      .humany-component-contact-link {
        display: flex;
        justify-content: flex-end;
        padding: 0px;

        span {
          color: ${customColors.baseBackgroundLight};
          ${Body3}
        }

        a {
          color: ${customColors.baseBackgroundLight};
          ${Body3Strong}
        }
      }
    }

    /* Copyright footer */
    .humany-component-copyright {
      display: none;
    }

    /* Contact form */
    .humany-component-contact-method, .humany-component-contact-method-area {
      padding: 0;
      background-color: transparent;
      box-shadow: none;

      .humany-fieldset-description {
        color: ${customColors.baseBackground};
        padding-bottom: 12px;
      }

      .humany-contact-method-email {
        legend, .humany-fieldset-required-description {
          display: none;
        }

        .humany-form-component-fieldset {
          label {
            ${Info}
            color: ${customColors.baseBackground};

            strong {
              color: ${customColors.baseBackground};
            }
          }

          input, textarea {
            border: none;
            padding: 12px;

            &:focus-visible {
              outline: none;
            }
          }
        }

        .humany-form-component-submit {
          ${CTA}
          padding: 8px;
          border-radius: 8px;
          background-color: ${({ theme }) => theme.color.base.primary};
          height: 40px;
        }
      }

      .humany-contact-method-confirmation {
        padding: 32px 16px;
        background-color: ${({ theme }) => theme.color.surface.white100};
        box-shadow: 0px 3px 3px 0px ${({ theme }) => theme.color.surface.black20};
        text-align: center;

        h1 {
          ${Title3}
        }

        p {
          ${Body1}
        }
      }
    }

    /* Modal */
    .humany-component-modal {
      .humany-component-root-area {
        background-color: ${customColors.background};

        .humany-component-widget-header {
          padding: 0px;

          h1 {
            ${Title3}
            text-align: left;
          }
        }

        .humany-component-contact-list {
          .humany-list {
            padding: 0px 16px;

            li {
              border-bottom: 1px solid ${({ theme }) => theme.color.surface.black20};

              &:last-child {
                border-bottom: none;
              }

              a {
                padding: 16px 8px;

                i {
                  width: 24px;
                  height: 24px;
                  min-width: 24px;
                  min-height: 24px;
                  line-height: 20px;
                }

                span {
                  ${Body3}
                }
              }

              .humany-component-guide {
                .humany-guide-content {
                  background-color: white;
                  border: none;

                  .humany-guide-body {
                    padding: 0px 8px;
                  }

                  .humany-list {
                    padding: 8px 32px 8px 0px;

                    li {
                      border: none;

                      .humany-link {
                        background: linear-gradient(180deg, rgba(173, 173, 173, 0.40) 0%, rgba(165, 165, 165, 0.40) 79.69%, rgba(195, 195, 195, 0.40) 100%);
                        padding: 8px 32px;
                        width: auto;
                        border: none;
                        margin-right: 0px;
                        height: auto;

                        span {
                          ${CTA}
                          color: ${customColors.baseBackground};
                        }
                      }
                    }
                  }

                  .humany-contact-list {
                    .humany-list li .humany-link {
                      background: none;

                      i {
                        line-height: 45px;
                      }

                      .humany-contact-link-rich-content {
                        .humany-text {
                          ${Info}
                          color: ${customColors.baseBackground};
                        }
                        .humany-html {
                          ${Body3}
                          color: ${({ theme }) => theme.color.text.tertiaryInverted}
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .humany-component-back-link button {
        ${Body3Strong}
        color: ${customColors.baseBackgroundLight};
        text-decoration: none;

        svg {
          margin-right: 8px;

          line {
            stroke: ${customColors.baseBackgroundLight};
          }
        }
      }
    }

    /* Notification */
    .humany-notification-row {
      text-align: left;
      background-color: ${({ theme }) => theme.color.base.primaryLight};
      color: ${({ theme }) => theme.color.text.primaryInverted};

      svg path {
        stroke: ${({ theme }) => theme.color.surface.black100};
      }
    }

    /* Restore global CSS */
    .humany-html {
      ul {
        margin-left: 20px;
        li {
          list-style-type: disc;
        }
      }

      ol {
        margin-left: 20px;
        li {
          list-style-type: decimal;
        }
      }
    }
  }
`
