const scriptId = 'humany-script-hero'

/** append Humany script to root */
export const insertHumanyScript = (root: HTMLElement) => {
  const script = document.createElement('script')
  script.id = scriptId
  script.innerHTML = `(function(a,b,c,d,e,f,g){for(var h,i=/[?&]{1}(humany[^=]*)=([^&#]*)/g;h=i.exec(a.location.search);)c+=(-1<c.indexOf("?")?"&":"?")+h[1]+"="+h[2];   f=b.createElement(e),f.async=!0,f.src=c,g=b.getElementsByTagName(e)[0],g.parentNode.insertBefore(f,g),a[d]=a[d]||{_c:[],   configure:function(){a[d]._c.push(arguments)}};var j=d.toLowerCase();a[j]=a[j]||{_c:[],configure:function(){a[j]._c.push(arguments)}}})   (window,document,"//tv4play.humany.net/default/embed.js","Humany","script");`

  root.appendChild(script)
}

/** remove Humany script from root and window */
export const removeHumanyScript = (root: HTMLElement) => {
  const script = document.getElementById(scriptId)

  if (script) {
    root.removeChild(script)
  }

  if (window.Humany) {
    window.Humany = undefined
  }
}
