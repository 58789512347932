import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { nextConfig } from '@/helpers/env'

export const MTVFaq = () => {
  const router = useRouter()

  const iframeDomain = nextConfig.string('MTV_FAQ_DOMAIN')

  const [currentPath] = useState(() => {
    return router.asPath.includes('#') ? router.asPath.replace('/asiakaspalvelu#', '') : ''
  })
  const [iframeHeight, setIframeHeight] = useState(800)

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin.startsWith(iframeDomain)) {
        switch (event.data.type) {
          case 'CHANGE_URL':
            {
              const path = event.data.url.replace('/asiakaspalvelu', '')
              router.push({ hash: path })
            }
            break
          case 'CHANGE_HEIGHT':
            setIframeHeight(event.data.height)
            break
          case 'OPEN_URL':
            {
              const url = event.data.url
              const urlHostname = new URL(url).hostname
              const currentDomain = 'www.cmore.fi' // Will be changed to mtv.fi when they have updated the article links

              if (urlHostname === currentDomain) {
                const path = url.replace('https://' + currentDomain, window.location.origin)
                router.push(path)
              } else {
                if (event.data.target === '_blank') {
                  window.open(url, '_blank', 'noreferrer')
                } else {
                  window.location.href = url
                }
              }
            }
            break
          default:
            break
        }
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [iframeDomain, router])

  return (
    <div>
      <iframe title="FaQ" src={`${iframeDomain}/asiakaspalvelu${currentPath}`} width="100%" height={iframeHeight} />
    </div>
  )
}
