import React, { useEffect, useRef, useState } from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { brandConfig } from '@/config/brand'
import { useNativeWebviewStyling } from '@/hooks/use-native-webview-styling'
import { HumanyChat } from '@/pages/faq/humany-chat'
import { ForceHideConcentBannerStyle } from '@/styles/force-hide-concent-banner'
import { HumanyGlobalStyle } from './humany-global-style'
import { insertHumanyScript, removeHumanyScript } from './humany-script-hero'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    Humany?: unknown
  }
}

export const Faq = () => {
  const scriptWrapperRef = useRef(null)
  const [isScriptInserted, setIsScriptInserted] = useState(false)
  const t = useTranslations()
  const router = useRouter()

  useNativeWebviewStyling()

  useEffect(() => {
    const scriptRef = scriptWrapperRef.current

    if (window.Humany && !isScriptInserted) {
      // if we already have a Humany instance, we need to reload the page to get a new instance
      window.location.reload()
    }

    if (scriptRef) {
      setIsScriptInserted(true)
      insertHumanyScript(scriptRef)
    }

    return () => {
      if (scriptRef) removeHumanyScript(scriptRef)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Humany works by listening to the native hashchange event.
    // Next routing does not trigger a native hashchange event when only the hash has been changed,
    // so we trigger it manually if next does a hash-only navigation.
    const onHashChangeComplete = () => {
      window.dispatchEvent(new HashChangeEvent('hashchange'))
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }

    router.events.on('hashChangeComplete', onHashChangeComplete)
    return () => {
      router.events.off('hashChangeComplete', onHashChangeComplete)
    }
  }, [router.events])

  return (
    <div>
      <ForceHideConcentBannerStyle />
      <HumanyGlobalStyle />
      <HumanyChat />
      {/* Don't remove this anchor tag. It initializes the FAQ page. */}
      <a style={{ visibility: 'hidden' }} href={'//tv4play.humany.net/' + brandConfig.faq.id}>
        {t('main_menu__faq')}
      </a>
      <div ref={scriptWrapperRef} />
    </div>
  )
}
