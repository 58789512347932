import { useEffect } from 'react'
import Script from 'next/script'
import { createGlobalStyle } from 'styled-components'

const Styles = createGlobalStyle`
  .cx-widget.cx-theme-light * { border-color: #161615!important;}
  .cx-widget.cx-theme-light .cx-side-button-group .cx-side-button { background-color: #161615 !important;color: white}
  .cx-widget.cx-theme-light .cx-svg-icon-tone1 { fill: white!important; }
  .cx-widget.cx-theme-light .cx-branding *, .cx-widget.cx-theme-light .cx-footer * { fill: #161615!important; }
  .cx-widget.cx-theme-light .cx-btn-default { border-color: #161615!important; background: #fdfdfd!important; color: #444A52!important; }
  .cx-widget.cx-theme-light .cx-btn-primary { border-color: #161615!important; background: #161615!important; }
  .cx-widget.cx-theme-light .cx-error-btn.cx-btn.cx-btn-primary { border-color: #161615!important; background: #161615!important; }
  .cx-widget.cx-theme-light .cx-end-confirm.cx-btn.cx-btn-primary { border-color: #161615!important; }
  .cx-widget.cx-theme-light .cx-submit.cx-btn.cx-btn-primary { border-color: #161615!important; }
  .cx-widget.cx-theme-light .cx-form-control:focus { border-color: #161615!important; }
  .cx-widget.cx-theme-light input[type=text] { border-color: #161615!important; }
  .cx-widget.cx-theme-light input[type=email] { border-color: #161615!important; }
  .cx-widget.cx-theme-light .cx-form-control:focus { border-color: #161615!important; }
  .cx-widget.cx-theme-light .cx-wrapper { margin-top: 10px; }
  .cx-widget.cx-theme-light .cx-button-group button:focus { border-color: #161615!important; }
  .cx-widget.cx-theme-light .cx-message.cx-them .cx-bubble-arrow svg * { fill: #DAE1E8!important; }
  .cx-widget.cx-theme-light .cx-message.cx-them .cx-bubble { background-color: #DAE1E8!important; }
  .cx-widget.cx-theme-light .cx-titlebar 	{ background-color: #161615; color: white;}
  .cx-widget.cx-theme-light .cx-title { font-size: 20px !important; color: white !important;}
  .cx-widget.cx-theme-light .cx-message.cx-date { color: black!important;)
  .cx-widget.cx-theme-light .cx-common-container  { color: white;}
  .cx-widget.cx-theme-light .cx-button-container { background-color: #161615;)
  .cx-widget.cx-theme-light .cx-webchat .cx-form th .cx-control-label { border-color: #161615;)
  .cx-widget.cx-theme-light .cx-widget .cx-form-control{ border-color: #161615;)
  .cx-widget.cx-theme-light .cx-sidebar { background-color: #161615; color: white;)
  .cx-widget.cx-theme-light .cx-send{ background-color: black;color:black;)
`

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    CXBus: LegitimateAny
    _genesys: LegitimateAny
  }
}

export const HumanyChat = () => {
  const handleScriptLoad = () => {
    window.CXBus.configure({ debug: false, pluginsPath: 'https://apps.mypurecloud.de/widgets/9.0/plugins/' })
    window.CXBus.loadFile('https://widget.releasy.se/tv4play/js/widgettv4play.config.js').done(function () {
      window.CXBus.loadPlugin('widgets-core')
    })
  }

  useEffect(() => {
    // A very hacky way to override the default chat click listener to use our custom chat solution
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach((node) => {
            if (node instanceof Element && node.matches("[title='Chatta med oss']")) {
              node.addEventListener('click', (event) => {
                event.stopPropagation()
                event.preventDefault()
                window._genesys.widgets.bus.command('WebChat.open')
              })
            }
          })
        }
      }
    })
    const config = { childList: true, subtree: true }
    observer.observe(document.body, config)

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <>
      <Script src="https://apps.mypurecloud.de/widgets/9.0/cxbus.min.js" onLoad={handleScriptLoad} />
      <Styles />
    </>
  )
}
